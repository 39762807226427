.main-layout {
  min-width: 1300px;
  height: 100%;
}

.main-layout :global(.ant-layout-sider-children) {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.main-layout :global(.ant-menu) {
  flex: 1;
  overflow: auto;
  border: none;
  background-color: #292F4C;
}

.main-layout :global(.ant-menu-item) {
  height: 52px;
  line-height: 52px;
  border-left: 6px solid;
  border-left-color: transparent;
}

.main-layout :global(.ant-menu-item > *) {
  color: #696D82;
}

.main-layout :global(.ant-menu-item svg) {
  vertical-align: middle;
  margin: 0 5px 4px 10px;
}

.main-layout :global(.ant-menu-item-selected) {
  background-color: rgba(255, 255, 255, .3) !important;
  border-left-color:  #F14545;
}

.main-layout :global(.ant-menu-item-selected:after) {
  content: none;
}

.main-layout :global(.ant-menu-item:hover *),
.main-layout :global(.ant-menu-item-selected *) {
  color: #FFF;
}

.main-layout :global(.ant-menu-item:hover path),
.main-layout :global(.ant-menu-item-selected path) {
  fill: #FFF;
}

.main-layout :global(.ant-layout-header) {
  display: flex;
  justify-content: flex-end;
  height: 56px;
  background-color: #FFF;
}

.shop {
  height: 56px;
  line-height: 56px;
  color: #292F4C;
}

.shop > img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
}

.shop > span {
  margin-left: 10px;
  font-size: 14px;
}

.main-layout :global(.ant-layout-content) {
  height: 100%;
  padding: 10px;
  overflow: auto;
}

.logo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  background: linear-gradient(315deg, rgba(241, 69, 69, 1) 0%, rgba(255, 130, 68, 1) 100%);
}

.logo-wrapper .logo {
  margin-right: 3px;
}

.empty {
  padding-top: 150px;
}
